import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { IPivotStyles } from '@fluentui/react';
import fscreen from 'fscreen';
import { Excalidraw } from '@excalidraw/excalidraw';
import type { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';
import {
  AppState,
  ExcalidrawImperativeAPI,
  ExcalidrawProps
} from '@excalidraw/excalidraw/types/types';
import { roomState, whiteboardState } from '../atoms';
import useAbort from '../utils/hooks/use-abort';
import { useUserMedia } from '../utils/hooks/use-streams';
import ExternalController from '../comps/ExternalController';
import App from '../app';
import { UnityCanva } from '../comps/UnityCanva/UnityCanva';
import InitialData from './initialData';
import './styles.scss';

const pivotStyles: Partial<IPivotStyles> = {
  root: {
    // display: 'flex',
    // justifyContent: 'center'
  },
  itemContainer: {
    padding: '.5em',
    width: '300px',
    height: '225px'
  }
};

const LandingNew: FunctionComponent = () => {
  const [room, setRoom] = useRecoilState(roomState);
  const { startUserMedia, stopUserMedia } = useUserMedia();
  const onAbort = useAbort();
  let defaultKey = 'create';
  let defaultId: string | undefined;
  const path = window.location.pathname;
  const excalidrawRef = useRef<ExcalidrawImperativeAPI>(null);

  const [viewModeEnabled, setViewModeEnabled] = useState(false);
  const [zenModeEnabled, setZenModeEnabled] = useState(false);
  const [gridModeEnabled, setGridModeEnabled] = useState(false);
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const [canvasUrl, setCanvasUrl] = useState<string | null>(null);
  const [exportWithDarkMode, setExportWithDarkMode] = useState<boolean>(false);
  const [shouldAddWatermark, setShouldAddWatermark] = useState<boolean>(false);
  const [theme, setTheme] = useState<ExcalidrawProps['theme']>('light');
  const [isShow, setIsShow] = useState(false);
  const [whiteboard, setWhiteboard] = useRecoilState(whiteboardState);

  const REGEX = /^\/room\/(?<id>[0-9a-zA-Z-_]+)/;
  const match = path.match(REGEX);
  if (match) {
    defaultKey = 'join';
    defaultId = match.groups?.id;
  }
  // useEffect(() => {
  //   if (fscreen.fullscreenElement) fscreen.exitFullscreen();
  // }, []);

  const [isVisible, setIsVisible] = useState(false);

  const handleSetVisible = async () => {
    setIsVisible(!isVisible);
    if (isVisible) {
      await stopUserMedia('videoinput');
      await stopUserMedia('audioinput');
      onAbort();
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        left: 0,
        top: 0,
        background: 'blue',
        boxSizing: 'border-box'
      }}>
      <ExternalController />
      <UnityCanva />
      {room && <App />}
      {whiteboard && (
        <div className="excalidraw-wrapper">
          <Excalidraw
            ref={excalidrawRef}
            initialData={InitialData}
            onChange={(elements: readonly ExcalidrawElement[], state: AppState) =>
              console.log('Elements :', elements, 'State : ', state)
            }
            onPointerUpdate={(payload) => console.log(payload)}
            onCollabButtonClick={() => window.alert('You clicked on collab button')}
            viewModeEnabled={viewModeEnabled}
            zenModeEnabled={zenModeEnabled}
            gridModeEnabled={gridModeEnabled}
            theme={theme}
            isCollaborating={!!whiteboard}
            name="Buga world"
          />
        </div>
      )}
    </div>
  );
};

export default LandingNew;
