import { CommandBar, DefaultButton, ThemeProvider, useTheme } from '@fluentui/react';
import type { ICommandBarItemProps, IButtonProps } from '@fluentui/react';
import { FunctionComponent, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useFullScreen } from '../../comps/full-screen';
import {
  audioDevicesState,
  videoDevicesState,
  currentCameraIdState,
  currentMicIdState,
  remoteStreamsState
} from '../../atoms';
import { useDisplayMedia, useUserMedia } from '../../utils/hooks/use-streams';
import {
  LeaveButtonStyles,
  buttonStyles,
  containerStyles,
  lightOption,
  darkOption
} from './styles';
import { darkPaletteAlt, lightPaletteAlt } from '../../utils/theme/themes';
import { useTheme as useThemeType, useSetTheme } from '../../utils/theme/theme-context';
import useAbort from '../../utils/hooks/use-abort';
import InfoCallout from '../../comps/info-callout';

interface MyCommandBarProps {
  onClickPeople?: () => void;
  onClickChat?: () => void;
  onClickFullscreen?: () => void;
}

const MyCommandBar: FunctionComponent<MyCommandBarProps> = ({
  onClickPeople,
  onClickChat,
  onClickFullscreen
}) => {
  const theme = useTheme();
  const themeType = useThemeType();
  const setTheme = useSetTheme();

  const currentMicId = useRecoilValue(currentMicIdState);
  const currentCameraId = useRecoilValue(currentCameraIdState);
  const audioDevices = useRecoilValue(audioDevicesState);
  const videoDevices = useRecoilValue(videoDevicesState);
  const { displayMediaStatus, startDisplayMedia, stopDisplayMedia } = useDisplayMedia();
  const { startUserMedia, stopUserMedia } = useUserMedia();

  const { isFullscreen } = useFullScreen();
  const onAbort = useAbort();

  const isRemoteDisplay = !!useRecoilValue(remoteStreamsState).find((r) => r.isDisplay);

  const [mediaBtnsDisabled, setMediaBtnsDisabled] = useState(false);

  const iconMuted = {
    color: theme.palette.neutralDark
  };

  const items: ICommandBarItemProps[] = [
    {
      iconProps: currentMicId
        ? { iconName: 'Microphone' }
        : { iconName: 'MicOff', style: iconMuted },
      onClick: () => {
        // eslint-disable-next-line
        const dummyDevice: any = {
          kind: 'audioinput'
        };
        setMediaBtnsDisabled(true);
        if (!currentMicId)
          startUserMedia(audioDevices.length ? audioDevices[0] : dummyDevice).finally(() =>
            setMediaBtnsDisabled(false)
          );
        else stopUserMedia('audioinput').finally(() => setMediaBtnsDisabled(false));

      },
      disabled: mediaBtnsDisabled,
      buttonStyles,
      key: 'audioToggle',
      // iconOnly: true,
      text: 'Micro',
      tooltipHostProps: {
        content: 'Activer son micro',
        delay: 0
      },
      split: true,
      subMenuProps: !audioDevices.length
        ? undefined
        : {
            items: audioDevices.map((device) => ({
              key: device.deviceId,
              text: device.label,
              iconProps:
                currentMicId === device.deviceId ? { iconName: 'TVMonitorSelected' } : undefined,
              onClick: () => {
                startUserMedia(device);
              }
            }))
          }
    },
    {
      iconProps: currentCameraId
        ? { iconName: 'Video' }
        : { iconName: 'VideoOff', style: iconMuted },
      onClick: () => {
        // eslint-disable-next-line
        const dummyDevice: any = {
          kind: 'videoinput'
        };
        setMediaBtnsDisabled(true);
        
        if (!currentCameraId)
          startUserMedia(videoDevices.length ? videoDevices[0] : dummyDevice).finally(() =>
            setMediaBtnsDisabled(false)
          );
        else stopUserMedia('videoinput').finally(() => setMediaBtnsDisabled(false));
      },
      disabled: mediaBtnsDisabled,
      buttonStyles,
      key: 'videoToggle',
      // iconOnly: true,
      text: 'Caméra',
      tooltipHostProps: {
        content: 'Toggle video',
        delay: 0
      },
      split: true,
      subMenuProps: !videoDevices.length
        ? undefined
        : {
            items: videoDevices.map((device) => ({
              key: device.deviceId,
              text: device.label,
              iconProps:
                currentCameraId === device.deviceId ? { iconName: 'TVMonitorSelected' } : undefined,
              onClick: () => {
                startUserMedia(device);
              }
            }))
          }
    },
    {
      key: 'screen',
      text: "Partage d'écran",
      // iconOnly: true,
      disabled: displayMediaStatus !== 'on' && isRemoteDisplay,
      iconProps: {
        iconName: 'ScreenCast',
        style: displayMediaStatus !== 'on' ? iconMuted : {}
      },
      buttonStyles,
      tooltipHostProps: {
        content:
          displayMediaStatus === 'on'
            ? 'Arrêter le partage'
            : !isRemoteDisplay
            ? 'Partager son écran'
            : "Une personne est déjà en train de partager son écran",
        delay: 0
      },
      onClick: () => {
        if (displayMediaStatus === 'on') stopDisplayMedia();
        else startDisplayMedia();
      }
    }
  ];
  const overflowProps: IButtonProps = { ariaLabel: 'More commands' };
  const overflowItems: ICommandBarItemProps[] = [
    {
      key: 'theme',
      text: 'Choose theme',
      secondaryText: themeType,
      iconProps: { iconName: 'Contrast' },
      subMenuProps: {
        items: [
          {
            key: 'light',
            text: 'Light',
            className: lightOption,
            onClick: () => themeType === 'dark' && setTheme?.('light')
          },
          {
            key: 'dark',
            text: 'Dark',
            className: darkOption,
            onClick: () => themeType === 'light' && setTheme?.('dark')
          }
        ]
      }
    },
    {
      key: 'fullscreen',
      text: 'Toggle fullscreen',
      // eslint-disable-next-line no-nested-ternary
      secondaryText: isFullscreen === undefined ? '' : isFullscreen ? 'On' : 'Off',
      iconProps: { iconName: 'Fullscreen' },
      onClick: () => onClickFullscreen?.()
    }
  ];

  const [showInfo, setShowInfo] = useState(false);

  const farItems: ICommandBarItemProps[] = [
    {
      // eslint-disable-next-line
      commandBarButtonAs: ({ text, key }) => (
        <DefaultButton onClick={() => onAbort()} text={text} key={key} styles={LeaveButtonStyles} />
      ),
      key: 'leave',
      text: 'Leave'
    }
  ];

  const palette = themeType === 'dark' ? darkPaletteAlt : lightPaletteAlt;
  return (
    <ThemeProvider theme={{ ...palette }}>
      <CommandBar
        styles={containerStyles}
        items={items}
        overflowButtonProps={overflowProps}
        farItems={farItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
      {showInfo && (
        <InfoCallout
          onDismiss={() => setShowInfo(false)}
          target=".commandbar-info-button"
          showFooter
        />
      )}
    </ThemeProvider>
  );
};
export default MyCommandBar;
