import { FontSizes, FontWeights, mergeStyles } from '@fluentui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NeutralColors } from '@fluentui/theme';
import type { IButtonStyles, ICommandBarStyles } from '@fluentui/react';
// eslint-disable-next-line @typescript-eslint/no-namespace
const DarkBlueBuga = '#000C7A';

export const buttonStyles: IButtonStyles = {
  icon: {
    fontSize: FontSizes.xLarge
  },
  root: {
    backgroundColor: DarkBlueBuga,
  },
  splitButtonDivider: {
    backgroundColor: DarkBlueBuga,
  },
  splitButtonMenuButton: {
    backgroundColor: DarkBlueBuga,
  },
  splitButtonMenuFocused: {
    backgroundColor: DarkBlueBuga,
  },
  splitButtonMenuButtonExpanded: {
    backgroundColor: DarkBlueBuga,
  },
};

export const lightOption = mergeStyles({
  backgroundColor: DarkBlueBuga,
  '& :hover': {
    backgroundColor: DarkBlueBuga,
  },
  '& button, & button:hover': {
    color: DarkBlueBuga,
  }
});
export const darkOption = mergeStyles({
  backgroundColor: DarkBlueBuga,
  '& :hover': {
    backgroundColor: DarkBlueBuga,
  },
  '& button, & button:hover': {
    color: NeutralColors.white
  }
});

export const containerStyles: ICommandBarStyles = {
  root: {
    padding: '0 .5em',
    height: '3rem',
    backgroundColor: DarkBlueBuga
  }
};

export const LeaveButtonStyles = {
  root: {
    margin: 'auto .5em',
    transition: 'all .1s ease',
    display: 'none'
  },
  rootHovered: {
    backgroundColor: '#CE0B1B',
    color: 'white'
  },
  label: {
    fontSize: FontSizes.mediumPlus,
    fontWeight: FontWeights.regular
  }
};
