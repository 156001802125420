import { useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import CommanBar from './command-bar';
import SidePanel from './side-panel';
import VideoBoxes from './video-boxes';
import Connections from './connections';
import Fullscreen from '../comps/full-screen';
import { roomState, displayCommandBarState } from '../atoms';

const App: FunctionComponent = () => {
  const room = useRecoilValue(roomState);
  useEffect(() => {
    document.title =
      room?.name ||
      (room?.created_by && `Meeting by ${room?.created_by}`) ||
      'A Buga World meeting';
    return () => {
      document.title = 'Buga World';
    };
  }, [room]);
  const commandBarStatus = useRecoilValue(displayCommandBarState);
  return (
    <>
      {commandBarStatus && <CommanBar />}
      <Connections />
      <VideoBoxes />
    </>
  );
};

export default App;
